<template lang="pug">
.app-boot
  .loading-page(v-if="waiting")
    h1.title Loading...
    b-progress(type='is-primary')
  .choose-path(v-if="!waiting && !user")
    img.logo(alt="nutworth logo" src="@/assets/img/logo.png")
    h1.title Welcome!
    router-link.button.is-primary(:to='{name: "sign_up"}') Create account
    router-link.button(:to='{name: "login"}') I have an account
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return { waiting: true }
  },
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        // Waiting for auth
        if (user === undefined) {
          return
        }
        this.waiting = false
        // User is logged in, take them to their data
        if (user) {
          this.$router
            .push({
              path: '/portfolio/data',
              params: this.$route.query
            })
            .catch(() => {})
        }
        // No log in, offer them a choice
      },
      immediate: true
    }
  },
  mounted() {
    if (document.referrer.includes('android-app://')) {
      this.isInTWA()
    }
  },
  methods: mapActions('app', ['isInTWA']),
  metaInfo: {
    title: 'Welcome'
  }
}
</script>

<style lang="sass" scoped>
.app-boot
  position:        fixed
  top:             0
  left:            0
  right:           0
  bottom:          0
  z-index:         250
  background:      white
.loading-page
  position:        fixed
  top:             0
  left:            0
  right:           0
  bottom:          0
  display:         flex
  flex-direction: column
  align-items:     center
  justify-content: center
.choose-path
  position:        fixed
  top:             0
  left:            0
  right:           0
  bottom:          0
  display:         flex
  flex-direction: column
  align-items:     center
  justify-content: center
.progress-wrapper
  width: 250px
.button
  width: 250px
  margin-bottom: 0.5rem
.logo
  width: 80px
  height: 80px
  border: 1px solid #a0a0a0
  border-radius: 50%
  padding: 0.5rem
  margin-bottom: 2rem
  box-shadow: 0px 1px 2px #ccc
</style>
